export const orderedProductsData = [
  {
    id: 1,
    name: "Platinum web hosting package9",
    desc: "Down 35mb, Up 100mb",
    quantity: 2,
    rate: 65.0,
  },
  {
    id: 2,
    name: "2 Page website design",
    desc: "Includes basic wireframes and responsive templates",
    quantity: 1,
    rate: 2100.0,
  },
  {
    id: 3,
    name: "Mobile App Development",
    desc: "Includes responsive navigation",
    quantity: 8,
    rate: 500.0,
  },
];
