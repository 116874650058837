export const orders = [
  {
    id: 1,
    product: 'Apple MacBook Pro 15"',
    quantity: 1,
    productKey: "Z0V20008N",
    specification: ["2.9GHz 6-core 8th-Gen Intel Core i9", "32GB RAM"],
    price: 1345,
  },
  {
    id: 2,
    product: "Apple iMac Pro",
    quantity: 1,
    productKey: "",
    specification: [
      "27-inch with Retina 5K Display",
      "3.0GHz 10-core Intel Xeon W",
      "1TB",
    ],
    price: 2010,
  },
];

export const shippingAddress = [
  {
    id: 1,
    name: "Antony Hopkins",
    street: "2392 Main Avenue",
    city: "Pensaukee",
    state: "New Jersey",
    zip: "02139",
    phone: "+(856) 929-229",
  },
  {
    id: 2,
    name: "Robert Bruce",
    street: "3448 Ile De France St #242",
    city: "Fort Wainwright",
    state: "Alaska",
    zip: "99703",
    phone: "+(901) 637-734",
  },
];
