import resultImg from "assets/img/team/2-thumb.png";
export const inDepthItems = [
  {
    name: "Email",
    progressbarWidth: ["33", "70", "40", "50"],
  },
  {
    name: "Phone",
    progressbarWidth: ["60", "50", "40", "20"],
  },
  {
    name: "Website",
    progressbarWidth: ["70", "30", "50", "75"],
  },
  {
    name: "Chat",
    progressbarWidth: ["87", "95", "20", "30"],
  },
  {
    name: "Urgent",
    progressbarWidth: ["87", "70", "90", "85"],
  },
  {
    name: "Medium",
    progressbarWidth: ["70", "60", "50", "40"],
  },
  {
    name: "High",
    progressbarWidth: ["60", "70", "80", "90"],
  },
  {
    name: "Low",
    progressbarWidth: ["33", "25", "30", "20"],
  },
  {
    name: "Open",
    progressbarWidth: ["20", "30", "40", "50"],
  },
  {
    name: "Question",
    progressbarWidth: ["18", "30", "25", "15"],
  },
];

export const surveyResults = [
  {
    name: "Amelia",
    avatar: {
      name: "Amelia",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 36,
      count: 17,
      date: "Last Day 19",
      progressBarWidth: [80, 60],
      countUp: true,
    },
    dissatisfied: {
      total: 52,
      count: 33,
      date: "Last Day 19",
      progressBarWidth: [60, 50],
      countUp: true,
    },
  },
  {
    name: "Bentley",
    avatar: {
      name: "Bentley",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 41,
      count: 17,
      date: "Last Day 24",
      progressBarWidth: [90, 80],
      countUp: true,
    },
    dissatisfied: {
      total: 23,
      count: 1,
      date: "Last Day 24",
      progressBarWidth: [95, 75],
      countUp: false,
    },
  },
  {
    name: "Abigail",
    avatar: {
      name: "Abigail",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 52,
      count: 27,
      date: "Last Day 25",
      progressBarWidth: [70, 80],
      countUp: true,
    },
    dissatisfied: {
      total: 12,
      count: 13,
      date: "Last Day 25",
      progressBarWidth: [70, 90],
      countUp: false,
    },
  },
  {
    name: "Christopher",
    avatar: {
      name: "Christopher",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 14,
      count: 16,
      date: "Last Day 30",
      progressBarWidth: [50, 80],
      countUp: false,
    },
    dissatisfied: {
      total: 25,
      count: 13,
      date: "Last Day 38",
      progressBarWidth: [60, 70],
      countUp: false,
    },
  },
  {
    name: "Declan",
    avatar: {
      img: resultImg,
      size: "xl",
    },
    satisfied: {
      total: 15,
      count: 30,
      date: "Last Day 45",
      progressBarWidth: [60, 85],
      countUp: false,
    },
    dissatisfied: {
      total: 32,
      count: 7,
      date: "Last Day 39",
      progressBarWidth: [30, 70],
      countUp: false,
    },
  },
  {
    name: "Bentley",
    avatar: {
      name: "Bentley",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 41,
      count: 17,
      date: "Last Day 24",
      progressBarWidth: [90, 80],
      countUp: true,
    },
    dissatisfied: {
      total: 23,
      count: 1,
      date: "Last Day 24",
      progressBarWidth: [95, 75],
      countUp: false,
    },
  },
  {
    name: "Abigail",
    avatar: {
      name: "Abigail",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 52,
      count: 27,
      date: "Last Day 25",
      progressBarWidth: [70, 80],
      countUp: true,
    },
    dissatisfied: {
      total: 12,
      count: 13,
      date: "Last Day 25",
      progressBarWidth: [70, 90],
      countUp: false,
    },
  },
  {
    name: "Christopher",
    avatar: {
      name: "Christopher",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 14,
      count: 16,
      date: "Last Day 30",
      progressBarWidth: [50, 80],
      countUp: false,
    },
    dissatisfied: {
      total: 25,
      count: 13,
      date: "Last Day 38",
      progressBarWidth: [60, 70],
      countUp: false,
    },
  },
  {
    name: "Declan",
    avatar: {
      img: resultImg,
      size: "xl",
    },
    satisfied: {
      total: 15,
      count: 30,
      date: "Last Day 45",
      progressBarWidth: [60, 85],
      countUp: false,
    },
    dissatisfied: {
      total: 32,
      count: 7,
      date: "Last Day 39",
      progressBarWidth: [30, 70],
      countUp: false,
    },
  },
  {
    name: "Amelia",
    avatar: {
      name: "Amelia",
      size: "xl",
      round: "circle",
    },
    satisfied: {
      total: 36,
      count: 17,
      date: "Last Day 19",
      progressBarWidth: [80, 60],
      countUp: false,
    },
    dissatisfied: {
      total: 52,
      count: 33,
      date: "Last Day 19",
      progressBarWidth: [60, 50],
      countUp: false,
    },
  },
];

export const ticketVolume = [
  [45, 35, 55, 55, 55, 45],
  [58, 42, 65, 65, 65, 30],
  [38, 25, 42, 42, 42, 45],
  [62, 45, 75, 75, 75, 55],
];

export const topCustomerData = {
  monday: [18, 50, 45, 80, 45, 60, 55, 82, 61, 50],
  tuesday: [50, 45, 32, 74, 45, 55, 85, 30, 25, 50],
  wednesday: [88, 70, 75, 54, 45, 44, 25, 65, 11, 20],
  thursday: [20, 30, 40, 50, 70, 80, 85, 40, 30, 20],
  friday: [18, 50, 45, 75, 45, 80, 85, 65, 61, 50],
  saturday: [25, 50, 45, 75, 80, 44, 55, 85, 61, 45],
  sunday: [11, 50, 45, 78, 45, 54, 80, 90, 50, 65],
};
