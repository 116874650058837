import logoStanford from "assets/img/logos/stanford.png";
import logoStaten from "assets/img/logos/staten.png";
import logoTjHeighSchool from "assets/img/logos/tj-heigh-school.png";

export default [
  {
    id: 1,
    logo: logoStanford,
    institution: "Stanford University",
    degree: "Computer Science and Engineering",
    duration: "2010 - 2014 • 4 yrs",
    location: "California, USA",
    verified: true,
  },
  {
    id: 2,
    logo: logoStaten,
    institution: "Staten Island Technical High School",
    degree: "Higher Secondary School Certificate, Science",
    duration: "2008 - 2010 • 2 yrs",
    location: "New York, USA",
    verified: true,
  },
  {
    id: 3,
    logo: logoTjHeighSchool,
    institution: "Thomas Jefferson High School for Science and Technology",
    degree: "Secondary School Certificate, Science",
    duration: "2003 - 2008 • 5 yrs",
    location: "Alexandria, USA",
    verified: true,
  },
];
