import logoG from "assets/img/logos/g.png";
import logoApple from "assets/img/logos/apple.png";
import logoNike from "assets/img/logos/nike.png";

export default [
  {
    id: 1,
    logo: logoG,
    title: "Big Data Engineer",
    company: "Google",
    startDate: "Apr 2012",
    endDate: "Present",
    duration: "6 yrs 9 mon",
    location: "California, USA",
    verified: true,
    url: "#!",
  },
  {
    id: 2,
    logo: logoApple,
    title: "Software Engineer",
    company: "Apple",
    startDate: "Jan 2012",
    endDate: "Apr 2012",
    duration: "4 mon",
    location: "California, USA",
    verified: true,
    url: "#!",
  },
  {
    id: 3,
    logo: logoNike,
    title: "Mobile App Developer",
    company: "Nike",
    startDate: "Jan 2011",
    endDate: "Apr 2012",
    duration: "1 yrs 4 mon",
    location: "Beaverton, USA",
    verified: true,
    url: "#!",
  },
];
