import image1 from "assets/img/team/1-thumb.png";
import image2 from "assets/img/team/2-thumb.png";

export const messages = [
  {
    img: image1,
    type: "via email",
    email: "emma@watson.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Improve in A purposed Manner",
    salutation: "Hi",
    message:
      "The television I ordered from your site was delivered with a cracked screen. I need some help with a refund or a replacement.",
    conclusion: "Here is the order number FD07062010",
    closing: "Thanks",
    sender: "Emma Watson",
    attached: true,
  },
  {
    img: image2,
    type: "replied",
    email: "mike@support.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Television with cracked screen",
    salutation: "Hi Emma Waston,",
    message:
      "I am sorry to hear about your experience with our TV. It sounds like you received a damaged product. Please provide me with the order number and we will work to resolve this issue as quickly as possible.",
    conclusion: "We are here to help!",
    closing: "Thanks",
    sender: "Mike",
    attached: false,
  },
  {
    img: image1,
    type: "via email",
    email: "emma@watson.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Cracked Television screen Issue",
    salutation: "Hi Mr. Mike,",
    message:
      "Thanks for resolving the issue as per request. It worked as I wanted. I hope to work with you ahead. thanks again.",
    conclusion: "",
    closing: "Regards",
    sender: "Emma Watson",
    attached: false,
  },
  {
    img: image2,
    type: "replied",
    email: "mike@support.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Cracked Television screen Issue",
    salutation: "Hi Emma Waston,",
    message:
      "Glad to help you. Buzz us anytime if you have any other queries regarding our products. Would you please consider rate us on the board?",
    conclusion: "",
    closing: "Thanks,",
    sender: "Mike",
    attached: false,
  },
  {
    img: image2,
    type: "replied",
    email: "mike@support.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Cracked Television screen Issue",
    salutation: "Hi Emma Waston,",
    message:
      "If you like our support service, please leave your valuable feedback and review us with a 5-star.",
    conclusion: "",
    closing: "Thanks,",
    sender: "Mike",
    attached: false,
  },
  {
    img: image1,
    type: "via email",
    email: "emma@watson.com",
    date: "01 March, 2020",
    time: "8:40 AM (1 Day ago)",
    subject: "Cracked Television screen Issue",
    salutation: "Hi Mr. Mike,",
    message:
      "The support you provided was really good, and I got the correct solution for my problems.",
    conclusion: "",
    closing: "Regards,",
    sender: "Emma Watson",
    attached: false,
  },
];
