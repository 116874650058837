export const lmsStatistics = [
  {
    id: 0,
    title: "New Learners",
    amount: 4968,
    amountLastMonth: 4203,
    icon: "user-graduate",
    color: "primary",
    className:
      "border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0",
  },
  {
    id: 1,
    title: "New Trainers",
    amount: 324,
    amountLastMonth: 301,
    icon: "chalkboard-teacher",
    color: "info",
    className:
      "border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0",
  },
  {
    id: 2,
    title: "New Courses",
    amount: 3712,
    amountLastMonth: 2779,
    icon: "book-open",
    color: "success",
    className:
      "border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0",
  },
  {
    id: 3,
    title: "Refunds",
    amount: 1054,
    amountLastMonth: 1201,
    icon: "dollar-sign",
    color: "warning",
    className: "pt-4 p-xxl-0 pb-0 pe-md-0",
  },
];

export const trendingKeywords = [
  { name: "Blockchain", v: 160, color: "#2A7BE4" },
  { name: "NFT", v: 20, color: "#1956A6" },
  { name: "HTML", v: 90, color: "#195099" },
  { name: "Crypto", v: 57, color: "#2A7BE4" },
  { name: "Photoshop", v: 117, color: "#2A7BE4" },
  { name: "UX", v: 20, color: "#1956A6" },
  { name: "AWS", v: 90, color: "#195099" },
  { name: "3D", v: 33, color: "#9DBFEB" },
  { name: "Writing", v: 117, color: "#2A7BE4" },
  { name: "Blender", v: 90, color: "#195099" },
  { name: "UI/UX", v: 33, color: "#9DBFEB" },
  { name: "Blockchain", v: 117, color: "#2A7BE4" },
  { name: "css", v: 20, color: "#1956A6" },
  { name: "Marketing", v: 90, color: "#195099" },
  { name: "Meta", v: 33, color: "#9DBFEB" },
  { name: "js", v: 12, color: "#0F67D9" },
  { name: "FOREX", v: 66, color: "#7FA5D5" },
  { name: "UI", v: 33, color: "#8ABBFB" },
  { name: "sql", v: 20, color: "#1956A6" },
  { name: "Vector", v: 56, color: "#85B6F5" },
  { name: "CAD", v: 28, color: "#6486B4" },
  { name: "Python", v: 66, color: "#2A7BE4" },
  { name: "Adobe", v: 66, color: "#68A0E9" },
  { name: "C#", v: 20, color: "#385780" },
  { name: "Branding", v: 88, color: "#74A2DE" },
  { name: "Bitcoin", v: 80, color: "#4E7AB4" },
  { name: "AI", v: 34, color: "#71AFFF" },
];

export const avgEnrollments = {
  onSaleCourse: [
    2000, 2800, 2200, 3001, 600, 600, 2000, 2000, 700, 1000, 200, 900, 1200,
  ],
  regularPaidCourse: [
    1700, 1200, 500, 700, 1500, 1100, 700, 1100, 2600, 2050, 1050, 600, 700,
  ],
};

export const topCourses = [
  {
    title: "Advanced Design Tools for Modern Designs",
    trainer: "Bill finger",
    published: "01/10/21",
    enrolled: "47,726",
    price: "$39.99",
  },
  {
    title: "Photograpy Basics: Get Familiar Standing Behind Lens",
    trainer: "Bruce Timm",
    published: "11/12/21",
    enrolled: "38,541",
    price: "$19.99",
  },
  {
    title: "Abstract Painting: Zero to Mastery in Traditional Medium",
    trainer: "J. H. Williams III",
    published: "03/09/21",
    enrolled: "35,666",
    price: "$45.49",
  },
  {
    title: "Character Design Masterclass: Your First Supervillain",
    trainer: "Bill finger",
    published: "31/12/21",
    enrolled: "29,988",
    price: "$99.99",
  },
  {
    title: "Script Writing Masterclass: Introdution to Industry Cliches",
    trainer: "Bill finger",
    published: "31/08/21",
    enrolled: "92,632",
    price: "$69.50",
  },
  {
    title: "Composition in Comics: Easy to Read Between Panels",
    trainer: "Bill finger",
    published: "14/05/21",
    enrolled: "92,603",
    price: "$39.50",
  },
  {
    title: "Comic Page Layout: Analysing The Classics",
    trainer: "Bill finger",
    published: "09/06/21",
    enrolled: "32,106",
    price: "$49.50",
  },
  {
    title: "Inking: Choosing Between Analog vs Digital",
    trainer: "Bill finger",
    published: "09/06/21",
    enrolled: "9,312",
    price: "$39.99",
  },
  {
    title: "Character Art School: Character Drawing Course",
    trainer: "Bruce Timm",
    published: "01/09/21",
    enrolled: "3,625",
    price: "$65.99",
  },
  {
    title: "User Experience Design Essentials",
    trainer: "Bill finger",
    published: "15/12/21",
    enrolled: "1,202",
    price: "$25.20",
  },
  {
    title: "The Art & Science of Drawing",
    trainer: "J. H. Williams III",
    published: "03/09/21",
    enrolled: "35,666",
    price: "$45.49",
  },
  {
    title: "Abstract Painting: One-to-One",
    trainer: "Bill finger",
    published: "03/09/21",
    enrolled: "6,356",
    price: "$20.49",
  },
  {
    title: "Portrait Drawing Fundamentals Made Simple",
    trainer: "Bill finger",
    published: "05/10/20",
    enrolled: "10,356",
    price: "$36.49",
  },
  {
    title: "Anatomy for Figure Drawing",
    trainer: "J. H. Williams",
    published: "26/10/20",
    enrolled: "12,386",
    price: "$30.99",
  },
  {
    title: "Complete Perspective Drawing Course",
    trainer: "Bruce Timm",
    published: "26/09/21",
    enrolled: "6,757",
    price: "$23.99",
  },
  {
    title: "The Ultimate Animal Drawing Course",
    trainer: "Bruce Timm",
    published: "06/12/21",
    enrolled: "7,658",
    price: "$19.99",
  },
];

export const UsersLocationTableData = [
  {
    country: "Bahrain",
    revenue: "$3997",
    users: "900",
    status: "Unavailable",
    variant: "danger",
  },
  {
    country: "Bangladesh",
    revenue: "$6700",
    users: "123k",
    status: "Early Beta",
    variant: "warning",
  },
  {
    country: "Belarus",
    revenue: "$5949",
    users: "6.5k",
    status: "Unavailable",
    variant: "danger",
  },
  {
    country: "Belgium",
    revenue: "$73000",
    users: "27k",
    status: "Available",
    variant: "success",
  },
];

export const marketingExpensesData = {
  digitalMarketing: [
    {
      id: 0,
      field: "Generate Backlinks",
      value: "$91.6k",
      colorOpacity: 100,
      color: "info",
    },
    {
      id: 1,
      field: "Email Marketing",
      value: "$183k",
      colorOpacity: 75,
      color: "info",
    },
    {
      id: 2,
      field: "Influencer Marketing",
      value: "$138k",
      colorOpacity: 50,
      color: "info",
    },
    {
      id: 3,
      field: "Google Ads",
      value: "$45.9k",
      colorOpacity: 25,
      color: "info",
    },
    {
      id: 4,
      field: "Social Media",
      value: "$183k",
      colorOpacity: 10,
      color: "info",
    },
  ],
  offlineMarketing: [
    {
      id: 0,
      field: "Event Sponsorship",
      value: "$91.6k",
      colorOpacity: 75,
      color: "primary",
    },
    {
      id: 1,
      field: "Outrich Event",
      value: "$183k",
      colorOpacity: 50,
      color: "primary",
    },
    {
      id: 2,
      field: "Ad Campaign",
      value: "$138k",
      colorOpacity: 25,
      color: "primary",
    },
  ],
};

export const courseEnrollments = [
  ["course", "Free Course", "Paid Course", "On sale Course"],
  ["Sun", 4300, 8500, 5000],
  ["Mon", 8300, 7300, 4500],
  ["Tue", 8600, 6200, 3600],
  ["Wed", 7200, 5300, 4500],
  ["Thu", 8000, 5000, 2600],
  ["Fri", 5000, 7000, 8800],
  ["Sat", 8000, 9000, 6000],
];
